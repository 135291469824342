<template>
  <div>我是详情页</div>
</template>

<script>
export default {
  name: "detail"
}
</script>

<style scoped lang="less">

</style>